import React, { useState } from "react"
import styled from "styled-components"
import ChatHeaderFilters from "./ChatHeaderFilters"
import { ChatFilterType } from "store/chat"
import Button from "components/atoms/button"
import { Box } from "components/atoms/_atoms"
import { MessageType } from "components/molecules/chat/types"
import { makeId } from "utils/id"

const FILTER_TYPES: any = {
  text: "Baza orzeczeń",
  ustawa: "Baza ustaw",
}

const ChatHeader = ({
  filters: defaultFilters,
  setFilters: defaultSetFilters,
  addToChat,
}: {
  filters: ChatFilterType
  setFilters: (e: ChatFilterType) => void
  addToChat: (e: MessageType[]) => void
}) => {
  const [filters, setFilters] = useState<ChatFilterType>({
    signatures: defaultFilters?.signatures,
    signaturesIds: defaultFilters?.signaturesIds,
    acts: defaultFilters?.acts,
    actsIds: defaultFilters?.actsIds,
  })
  const [showModal, setShowModal] = useState(false)

  const onSubmit = () => {
    const id = makeId(5)
    defaultSetFilters(filters)
    const filterTypesLabels = []
    if (filters.signatures) {
      filterTypesLabels.push("text")
    }
    if (filters.acts) {
      filterTypesLabels.push("ustawa")
    }

    addToChat([
      {
        id,
        type: "system",
        message: `Zmieniona bazę wiedzy: ${
          filterTypesLabels.length > 0
            ? filterTypesLabels.map(el => FILTER_TYPES[el]).join(", ")
            : "Brak"
        }`,
      },
    ])
    setShowModal(false)
  }

  return (
    <>
      <Wrapper>
        <Button type="secondary" icon="filters" onClick={() => setShowModal(true)}>
          Dostosuj bazę wiedzy
        </Button>
      </Wrapper>
      <ChatHeaderFilters
        filters={filters}
        showModal={showModal}
        setShowModal={setShowModal}
        setFilters={setFilters}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default ChatHeader

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  border-bottom: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
