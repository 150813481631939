import React, { useState } from "react"
import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"
import SearchHeader from "views/Panel/Search/SearchHeader"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { RootState } from "store/store"
import SearchContent from "views/Panel/Search/SearchContent"
import { Box } from "components/atoms/_atoms"
import { AISourceDocument } from "services/AIService"
import { P } from "components/atoms/typography"
import SearchDataTabs from "views/Panel/Search/SearchDataTabs"
import DocumentSignatureContent from "views/Panel/Search/content/DocumentSignatureContent"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import { useCustomQuery } from "hooks/useCustomQuery"

const Profile = () => {
  const { message: saveMessage, data: saveData } = useSelector((state: RootState) => state.search)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<AISourceDocument[] | null>(saveData)

  const [selectedDocumentId, setSelectedDocumentId] = useState<null | string>(null)

  const { isLoading: countLoading, data: count } = useCustomQuery<number>({ key: ['count'] }, () =>
    DocumentSignatureService.count(),
  )

  return (
    <>
      <Wrapper>
        <PanelContentLayout
          icon="search"
          title="Znajdź sygnaturę"
          text={
            countLoading || !count ? (
              <LoaderBlock height="22px" />
            ) : (
              <P margin="0">
                Szukaj spośrod{" "}
                <span>
                  {Intl.NumberFormat("pl-PL", {
                    style: "decimal",
                    maximumFractionDigits: 0,
                  }).format(count)}
                </span>{" "}
                dokumentów przy użyciu sztucznej inteligencji
              </P>
            )
          }
        >
          <SearchHeader
            loading={loading}
            setLoading={setLoading}
            setSelectedDocumentId={setSelectedDocumentId}
            setData={setData}
          />
            <SearchDataTabs
              length={data?.length}
              data={data
                ?.filter(function (item, pos) {
                  return data?.findIndex(el => el.metadata.id === item.metadata.id) == pos
                })
                .map(el => {
                  return {
                    id: el.metadata.id,
                    title: el.metadata.title,
                  }
                })}
              selectedDocumentId={selectedDocumentId}
              setSelectedDocumentId={setSelectedDocumentId}
            />
            <Box marginTop="M">
              {!selectedDocumentId ? (
                <SearchContent
                  loading={loading}
                  data={data}
                  setSelectedDocumentId={setSelectedDocumentId}
                />
              ) : (
                <DocumentSignatureContent id={selectedDocumentId} />
              )}
            </Box>
          {/*</SearchGrid>*/}
        </PanelContentLayout>
      </Wrapper>
      <Outlet />
    </>
  )
}

export default Profile

const SearchGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)`
  display: flex;
`
