import { createSlice } from "@reduxjs/toolkit"
import { AISourceDocument } from "services/AIService"

interface FolderState {
  q: string
  filters: {
    judgmentType: string[],
    courtType: string[],
    divisionType: string[],
    divisionCourtType: string[],
    sourceName: string[],
    judge: string,
    division: string,
  }
  message: string | null
  data: AISourceDocument[] | null
}

const INITIAL_FILTERS = {
  judgmentType: [],
  courtType: [],
  divisionType: [],
  divisionCourtType: [],
  sourceName: [],
  judge: "",
  division: "",
}

const initialState: FolderState = {
  q: "",
  filters: INITIAL_FILTERS,
  message: null,
  data: null
}

export const folderSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.q = action.payload.q
      state.filters = action.payload.filters
      state.message = action.payload.message
      state.data = action.payload.data
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setSearchData
} = folderSlice.actions

export default folderSlice.reducer
