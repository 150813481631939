import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Outlet, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import ChatInput from "components/molecules/chat/ChatInput"
import ChatHeader from "components/molecules/chat/ChatHeader"
import { Box } from "components/atoms/_atoms"
import { AIService } from "services/AIService"
import { makeId } from "utils/id"
import ChatContent from "components/molecules/chat/ChatContent"
import { MessageType } from "components/molecules/chat/types"
import { makeHistory, removeDuplicatesByMetadataId } from "utils/chat"
import { useChat } from "hooks/useChats"
import { updateChat, ChatFilterType } from "store/chat"

const ChatView = () => {
  const { chatId = "" } = useParams()

  return (
    <>
      <Wrapper key={chatId}>
        <Chat />
      </Wrapper>
      <Outlet />
    </>
  )
}

const Chat = () => {
  const { chatId = "" } = useParams()
  const dispatch = useDispatch()
  const chat = useChat(chatId)

  const [filters, setFilters] = useState<ChatFilterType>({
    signatures: chat?.filters?.signatures ?? true,
    signaturesIds: chat?.filters?.signaturesIds ?? [],
    acts: chat?.filters?.acts ?? true,
    actsIds: chat?.filters?.actsIds ?? [],
  })
  const [text, setText] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<MessageType[]>(chat?.messages ?? [])

  const addToChat = (items: MessageType[]) => {
    setMessages(prevState => {
      return [...prevState, ...items]
    })
  }

  const fetchChat = async (id: string, message: string) => {
    try {
      setLoading(true)
      const history = makeHistory(messages)
      const types = []
      const ids = []
      if (filters.signatures) {
        types.push("text")
        ids.push(...filters.signaturesIds.map(el => el.id))
      }
      if (filters.acts) {
        types.push("ustawa")
        ids.push(...filters.actsIds.map(el => el.id))
      }
      const { answer, question, source_documents } = await AIService.chat({
        message,
        history,
        types: types,
        ids: ids,
      })
      addToChat([
        {
          id,
          type: "ai",
          message: answer,
          question: question,
          sources: removeDuplicatesByMetadataId(source_documents).map(el => el.metadata),
        },
      ])
    } catch (e) {
      console.log(e)
      // SentryService.error("[ERROR ProfileOfferService.getOfferApplications]:", e)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = () => {
    if (chat && text && text !== "") {
      const id = makeId(5)
      setText("")
      addToChat([
        {
          id,
          type: "human",
          message: text,
        },
      ])

      fetchChat(id, text)
    }
  }

  useEffect(() => {
    if (chat && messages.length !== chat?.messages.length) {
      dispatch(
        updateChat({
          id: chatId,
          messages,
          filters,
        }),
      )
    }
  }, [messages])

  return (
    <>
      <ChatHeader filters={filters} setFilters={setFilters} addToChat={addToChat} />
      <ChatContent loading={loading} messages={messages} />
      <ChatInput loading={loading} value={text} setValue={setText} handleSubmit={handleSubmit} />
    </>
  )
}

export default ChatView

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1;
`
