"use client";

import React from "react"
import styled, { css } from "styled-components"
import { Box, common } from "components/atoms/_atoms";
import { BaseStyles } from "types/base-styles";

const Switch = ({
  name,
  value,
  disabled,
  onChange,
  ...rest
}: {
  name: string
  value: boolean
  disabled?: boolean
  onChange: (e: { name: string; value: boolean }) => void
} & BaseStyles) => {
  return (
    <Wrapper
      currentValue={value}
      disabled={disabled}
      onClick={disabled ? () => {} : () =>
        onChange({
          name,
          value: !value,
        })
      }
      {...rest}
    >
      <Content currentValue={value} />
    </Wrapper>
  )
}

export default Switch

const Content = styled.button<{
  currentValue: boolean
}>`
  display: block;
  width: ${({ theme }) => theme.space.S};
  height: ${({ theme }) => theme.space.S};
  min-height: ${({ theme }) => theme.space.S};
  min-width: ${({ theme }) => theme.space.S};
  max-height: ${({ theme }) => theme.space.S};
  max-width: ${({ theme }) => theme.space.S};
  background: white;
  border-radius: ${({ theme }) => theme.space.S};
  border: none;
  transition: transform 0.15s;
  transform: translateX(0%);

  ${({ currentValue }) =>
    currentValue &&
    css`
      transform: translateX(100%);
    `}
`

const Wrapper = styled(Box)<{
  currentValue: boolean
  disabled?: boolean
}>`
  ${common};
  width: calc(${({ theme }) => theme.space.S} * 2 + 4px);
  min-width: calc(${({ theme }) => theme.space.S} * 2 + 4px);
  height: calc(${({ theme }) => theme.space.S} + 4px);
  min-height: calc(${({ theme }) => theme.space.S} + 4px);
  padding: 2px;
  border-radius: ${({ theme }) => theme.space.S};
  cursor: pointer;
  transition: transform 0.15s;
  background: ${({ theme }) => theme.color.third};

  * {
    cursor: pointer;
  }
  
  ${({ currentValue }) =>
          currentValue &&
          css`
            background: ${({ theme }) => theme.color.primary};
              border-color: ${({ theme }) => theme.color.primary};
          `};

  ${({ disabled }) =>
          disabled &&
          css`
            cursor: not-allowed;
            
            * {
              cursor: not-allowed;
            }
          `};
`
