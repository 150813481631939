import React, { useState } from "react"
import styled from "styled-components"
import { Label } from "components/atoms/typography"
import { ChatFilterType } from "store/chat"
import { Box, Row } from "components/atoms/_atoms"
import Switch from "components/atoms/switch"
import { DocumentActType } from "services/types"
import Button from "components/atoms/button"
import Modal from "components/molecules/modal"

type SwitchType = Pick<ChatFilterType, "signatures" | "acts">

const ChatHeaderFilters = ({
  filters,
  showModal,
  setShowModal,
  setFilters,
  onSubmit,
}: {
  showModal: boolean
  setShowModal: (e: boolean) => void
  onSubmit: () => void
  filters: ChatFilterType
  setFilters: (e: ChatFilterType) => void
}) => {
  const { signatures, acts } = filters

  const [loadingActs, setLoadingActs] = useState<boolean>(false)
  const [inputActs, setInputActs] = useState("edcdeccedc")

  const [act, setAct] = useState<DocumentActType[]>([])

  const handleSwitchType = (name: keyof SwitchType, value: boolean) => {
    const obj = { ...filters }
    obj[name] = value

    const isAnyTrue = obj.acts || obj.signatures
    if (!isAnyTrue) {
      if (name === "acts") {
        obj.signatures = true
      } else if (name === "signatures") {
        obj.acts = true
      }
    }

    setFilters(obj)
  }

  return (
    <Modal
      show={showModal}
      type="side"
      icon="filters"
      title="Dostosuj bazę wiedzy"
      text="Dostosuj bazę wiedzy Parrot.ai do swoich potrzeb!"
      maxWidth="600px"
      setShow={setShowModal}
    >
      <Wrapper>
        <Content>
          <Row alignItems="center" justifyContent="space-between">
            <Label color="second">Baza orzeczeń</Label>
            <Switch
              name="signatures"
              value={signatures}
              onChange={e => handleSwitchType(e.name as keyof SwitchType, e.value)}
            />
          </Row>
        </Content>
        <Content>
          <Row alignItems="center" justifyContent="space-between">
            <Label color="second">Baza ustaw</Label>
            <Switch
              name="acts"
              value={acts}
              onChange={e => handleSwitchType(e.name as keyof SwitchType, e.value)}
            />
          </Row>
          {/*{acts && (*/}
          {/*  <Box marginTop="S">*/}
          {/*    <InputAutocomplete*/}
          {/*      name="judge"*/}
          {/*      label=""*/}
          {/*      placeholder="Wszystkie ustawy"*/}
          {/*      inputValue={inputActs}*/}
          {/*      onInputChange={setInputActs}*/}
          {/*      options={[]}*/}
          {/*      onChange={() => {}}*/}
          {/*      // defaultValue={act?.name}*/}
          {/*      onOptionChange={e => {}}*/}
          {/*      // options={ACTS.map(el => {*/}
          {/*      //   return {*/}
          {/*      //     label: el.title,*/}
          {/*      //     ...el,*/}
          {/*      //   }*/}
          {/*      // })}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Content>
        <Button icon="save" width="100%" marginTop="M" onClick={onSubmit}>
          Zapisz
        </Button>
      </Wrapper>
    </Modal>
  )
}

export default ChatHeaderFilters

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.S} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};

  &:last-child {
    border-bottom: none !important;
  }
`

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`
