import React, { useRef, useState } from "react"
import styled, { useTheme } from "styled-components"
import { useNavigate } from "react-router-dom"
import { Box } from "components/atoms/_atoms"
import { DocumentSearchSimilarModal } from "components/shared/Documents/components/DocumentSearchSimilar"

const SignatureContentInnerContentText = ({ text }: { text?: string }) => {
  const ref = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState<null | {
    type: "similar"
    value: string
  }>(null)

  const handleSearchSimilar = (q: string) => {
    setShowModal({
      type: "similar",
      value: q,
    })
  }

  return (
    <>
      <Wrapper>
        <Content className="app" ref={ref} dangerouslySetInnerHTML={{ __html: `${text}` }} />
        {/*<HighlightMenu*/}
        {/*  target={ref}*/}
        {/*  allowedPlacements={["top"]}*/}
        {/*  styles={{*/}
        {/*    boxShadow: theme.variable.boxShadow,*/}
        {/*    flexDirection: "column",*/}
        {/*  }}*/}
        {/*  menu={({ selectedText = "", setClipboard, setMenuOpen }) => (*/}
        {/*    <>*/}
        {/*      <IconButton*/}
        {/*        iconSize="L"*/}
        {/*        borderRadius="borderRadius"*/}
        {/*        hoverBackground="backgroundDarker"*/}
        {/*        size="45px"*/}
        {/*        icon="copy"*/}
        {/*        onClick={() =>*/}
        {/*          setClipboard(selectedText, () => {*/}
        {/*            alert("Copied to clipboard")*/}
        {/*          })*/}
        {/*        }*/}
        {/*      />*/}
              {/*<IconButton*/}
              {/*  iconSize="L"*/}
              {/*  borderRadius="borderRadius"*/}
              {/*  hoverBackground="backgroundDarker"*/}
              {/*  size="45px"*/}
              {/*  icon="search"*/}
              {/*  onClick={() => handleSearchSimilar(selectedText)}*/}
              {/*/>*/}
              {/*<Button*/}
              {/*  type="secondary"*/}
              {/*  icon="copy"*/}
              {/*  onClick={() =>*/}
              {/*    setClipboard(selectedText, () => {*/}
              {/*      alert("Copied to clipboard")*/}
              {/*    })*/}
              {/*  }*/}
              {/*>*/}
              {/*  Kopiuj tekst*/}
              {/*</Button>*/}
        {/*      <Button*/}
        {/*        type="secondary"*/}
        {/*        icon="search"*/}
        {/*        onClick={() => handleSearchSimilar(selectedText)}*/}
        {/*      >*/}
        {/*        Szukaj podobnych*/}
        {/*      </Button>*/}
        {/*      <Button type="secondary" icon="search" onClick={() => {}}>*/}
        {/*        Wyjasnij tekst*/}
        {/*      </Button>*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*/>*/}
      </Wrapper>
      <DocumentSearchSimilarModal
        show={showModal?.type === "similar"}
        setShow={() => setShowModal(null)}
        q={showModal?.value}
      />
    </>
  )
}

export default SignatureContentInnerContentText

const Wrapper = styled(Box)`
  flex: 1;
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
`

const Content = styled(Box)`
  * {
    font-family: auto !important;
    white-space: normal !important;
  }
`
