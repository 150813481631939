import axios from "axios"

export const API_URL = process.env.REACT_APP_API_AI_URL

interface Root<T> {
  data: T
}

export interface AISourceDocument {
  page_content: string
  metadata: Metadata
}

interface Metadata {
  id: string
  title: string
  type: string
  titleUpper: string
  courtType: string
  courtTypeStr: string
  judgmentType: string
  judgmentTypeStr: string
  date: string
  datetime: string
  divisionId: string
  divisionCategory: string
  divisionCode: string
  divisionType: string
  divisionName: string
  divisionCourtId: string
  divisionCourtCode: string
  divisionCourtName: string
  divisionCourtType: string
  divisionChamberId: string
  divisionChamberName: string
  judges: string[]
  sourceId: string
  sourceName: string
  keywords: string[]
  legalBases: string[]
}

const search = async (str: string, filters?: any): Promise<AISourceDocument[]> => {
  const { data } = await axios.get<null, Root<AISourceDocument[]>>(
    `${API_URL}/search?q=${str}&judgmentTypes=${filters?.judgmentType?.join(
      ",",
    )}&courtTypes=${filters?.courtType?.join(",")}&sourceNames=${filters?.sourceName?.join(
      ",",
    )}&divisionTypes=${filters?.divisionType?.join(
      ",",
    )}&divisionCourtTypes=${filters?.divisionCourtType?.join(",")}`,
  )

  return data.filter(el => el.metadata.type === "text")
}

const chat = async ({
  message,
  history = [],
  types,
  ids,
  id
}: {
  message: string
  history?: string[][]
  types?: string[]
  ids?: string[]
  id?: string
}): Promise<{
  answer: string
  question: string
  source_documents: [
    {
      page_content: string
      metadata: {
        id: string
        title: string
        type: string
      }
    },
  ]
}> => {
  const { data } = await axios.post<null, any>(
    `${API_URL}/chat`,
    {
      message,
      history,
      types,
      id,
      ids,
    },
  )

  return data
}

const quickAnswer = async ({
  id,
  message,
}: {
  id: string
  message: string
}): Promise<string> => {
  const { data } = await axios.post<null, Root<{
    answer: string
  }>>(`${API_URL}/quick-answer`, {
    id,
    message,
  })

  return data.answer
}

export const AIService = {
  search,
  chat,
  quickAnswer,
}
