import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { AIService, AISourceDocument } from "services/AIService"
import { Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import Button, { buttonStyle } from "components/atoms/button"
import Modal from "components/molecules/modal"
import CheckboxesGroup from "components/atoms/checkboxes-group"
import { t } from "utils/translator"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { DocumentSignatureDivision, DocumentSignatureJudge } from "services/types/DocumentSignature"
import { SentryService, UserHistoryService } from "services"
import { RootState } from "store/store"
import { setSearchData } from "store/search"
import { updateShowMenu } from "store/ui"

const INITIAL_FILTERS = {
  judgmentType: [],
  courtType: [],
  divisionType: [],
  divisionCourtType: [],
  sourceName: [],
  judge: "",
  division: "",
}

const SearchHeader = ({
  loading,
  setLoading,
  setSelectedDocumentId,
  setData,
  ...rest
}: BaseStyles & {
  loading: boolean
  setLoading: (e: boolean) => void
  setSelectedDocumentId: (e: string | null) => void
  setData: (e: AISourceDocument[] | null) => void
}) => {
  const dispatch = useDispatch()
  const {
    q: saveQ,
    filters: saveFilters,
    message,
    data,
  } = useSelector((state: RootState) => state.search)

  const [q, setQ] = useState(saveQ)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState(saveFilters)

  const timeoutJudgesId = useRef<number | null>()
  const timeoutDivisionId = useRef<number | null>()

  const [filtersLoading, setFiltersLoading] = useState<string | boolean>(false)
  const [searchJudges, setSearchJudges] = useState<DocumentSignatureJudge[]>([])
  const [judge, setJudge] = useState<DocumentSignatureJudge | null>(null)
  const [searchDivisions, setSearchDivisions] = useState<DocumentSignatureDivision[]>([])
  const [division, setDivision] = useState<DocumentSignatureDivision | null>(null)

  const handleSearch = async (e?: any) => {
    e?.preventDefault()
    dispatch(updateShowMenu(false))
    try {
      setLoading(true)
      setData(null)
      setSelectedDocumentId(null)

      const documents = await AIService.search(q, {
        judgmentType: filters.judgmentType,
        courtType: filters.courtType,
        sourceName: filters.sourceName,
        judge: judge ? judge?.name : "",
        divisionCode: division ? division?.code : "",
      })
      setData(documents)

      dispatch(
        setSearchData({
          q: q,
          filters: filters,
          message: q,
          data: documents,
        }),
      )
      UserHistoryService.addHistory({
        title: q,
        documentIds: documents.map(el => el.metadata.id),
      })
    } catch (e) {
      SentryService.error("[ERROR Search]:", e)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleSearchJudges = useCallback(async (val: string) => {
    //TODO: Change to use-query
    try {
      setFiltersLoading("judges")
      const { content } = await DocumentSignatureService.searchJudges(val)
      setSearchJudges(content)
    } catch (e) {
      setSearchJudges([])
      SentryService.error("[ERROR DocumentSignatureService.searchJudges]:", e)
    } finally {
      setFiltersLoading(false)
    }
  }, [])

  const handleSearchDivision = useCallback(
    async (val: string, divisionType: string[], divisionCourtType: string[]) => {
      //TODO: Change to use-query
      try {
        setFiltersLoading("division")
        const { content } = await DocumentSignatureService.searchDivision(
          val,
          divisionType,
          divisionCourtType,
        )
        setSearchDivisions(content)
      } catch (e) {
        setSearchDivisions([])
        SentryService.error("[ERROR DocumentSignatureService.searchDivision]:", e)
      } finally {
        setFiltersLoading(false)
      }
    },
    [],
  )

  useEffect(() => {
    if (timeoutJudgesId.current) {
      clearTimeout(timeoutJudgesId.current)
    }

    // @ts-ignore
    timeoutJudgesId.current = setTimeout(() => {
      if (filters.judge && filters.judge.length > 2) {
        handleSearchJudges(filters.judge)
      }
    }, 1000)
  }, [filters.judge])

  useEffect(() => {
    if (timeoutDivisionId.current) {
      clearTimeout(timeoutDivisionId.current)
    }

    // @ts-ignore
    timeoutDivisionId.current = setTimeout(() => {
      if (filters.division && filters.division.length > 2) {
        handleSearchDivision(filters.division, filters.divisionType, filters.divisionCourtType)
      }
    }, 1000)
  }, [filters.division])

  const onChange = (obj: { name: string; value: any }) => {
    const { name, value } = obj
    const newFilters: any = {
      ...filters,
      [name]: value,
    }

    setFilters(newFilters)
  }

  return (
    <>
      <Wrapper {...rest}>
        {/*<StyledSelect>*/}
        {/*  <option>A</option>*/}
        {/*  <option>B</option>*/}
        {/*  <option>C</option>*/}
        {/*</StyledSelect>*/}
        <form id="search_form">
          <Input
            placeholder="Podaj sygnaturę sprawy lub napisz czego szukasz"
            value={q}
            onChange={(e: any) => setQ(e.target.value)}
          />
          {/*<StyledButton type="secondary" icon="filters" onClick={() => setShowFilters(true)}>*/}
          {/*  Filtruj*/}
          {/*</StyledButton>*/}
          <StyledButton
            loading={loading}
            disabled={!q || q === ""}
            icon="search"
            submit
            onClick={handleSearch}
          >
            Szukaj
          </StyledButton>
        </form>
      </Wrapper>
      <Modal
        icon="filters"
        type="side"
        show={showFilters}
        setShow={setShowFilters}
        title="Filtruj dokumenty"
        bottomElement={
          <Box>
            <Button
              width="100%"
              marginBottom="XS"
              type="secondary"
              onClick={() => setFilters(INITIAL_FILTERS)}
            >
              Wyzeruj filtry
            </Button>
            <Button
              width="100%"
              onClick={() => {
                setShowFilters(false)
                if (q || q !== "") {
                  handleSearch()
                }
              }}
            >
              Filtruj dokumenty
            </Button>
          </Box>
        }
      >
        <FilterWrapper>
          <CheckboxesGroup
            type="secondary"
            name="judgmentType"
            label="Typ orzeczenia"
            value={filters?.judgmentType}
            onChange={onChange}
            options={[
              [t("DECISION"), "DECISION"],
              [t("RESOLUTION"), "RESOLUTION"],
              [t("SENTENCE"), "SENTENCE"],
              [t("REGULATION"), "REGULATION"],
              [t("REASONS"), "REASONS"],
            ]}
          />
          <CheckboxesGroup
            type="secondary"
            name="courtType"
            label="Typ organu orzekającego"
            value={filters?.courtType}
            onChange={onChange}
            options={[
              [t("COMMON"), "COMMON"],
              [t("SUPREME"), "SUPREME"],
              [t("ADMINISTRATIVE"), "ADMINISTRATIVE"],
              [t("CONSTITUTIONAL_TRIBUNAL"), "CONSTITUTIONAL_TRIBUNAL"],
              [t("NATIONAL_APPEAL_CHAMBER"), "NATIONAL_APPEAL_CHAMBER"],
            ]}
          />
          <CheckboxesGroup
            type="secondary"
            name="divisionType"
            label="Typ wydziału orzekającego"
            value={filters?.divisionType}
            onChange={onChange}
            options={[
              ["Cywilny", "Cywilny"],
              ["Gospodarczy", "Gospodarczy"],
              ["Karny", "Karny"],
              [
                "Penitencjarny i Nadzoru nad Wykonywaniem Orzeczeń Karnych",
                "Penitencjarny i Nadzoru nad Wykonywaniem Orzeczeń Karnych",
              ],
              ["Pracy", "Pracy"],
              ["Pracy i Ubezpieczeń Społecznych", "Pracy i Ubezpieczeń Społecznych"],
              ["Rodzinny i Nieletnich", "Rodzinny i Nieletnich"],
              ["Ubezpieczeń", "Ubezpieczeń"],
            ]}
          />
          <CheckboxesGroup
            type="secondary"
            name="divisionCourtType"
            label="Typ sądu orzekającego"
            value={filters?.divisionCourtType}
            onChange={onChange}
            options={[
              ["Sąd regionalny", "DISTRICT"],
              ["Sąd apelacyjny", "APPEAL"],
              ["Sąd okręgowy", "REGIONAL"],
            ]}
          />
          {/*<Box>*/}
          {/*  <Label marginBottom="S">Wydział orzekający</Label>*/}
          {/*  <InputAutocomplete*/}
          {/*    name="division"*/}
          {/*    onChange={onChange}*/}
          {/*    loading={filtersLoading === "division"}*/}
          {/*    defaultValue={division ? `${division?.name}, ${division?.court.name}` : undefined}*/}
          {/*    onOptionChange={e => setDivision(e)}*/}
          {/*    options={searchDivisions.map(el => {*/}
          {/*      return {*/}
          {/*        label: `${el.name}, ${el.court.name}`,*/}
          {/*        ...el,*/}
          {/*      }*/}
          {/*    })}*/}
          {/*  />*/}
          {/*</Box>*/}
          {/*<Box>*/}
          {/*  <Label marginBottom="S">Sędziowie orzekający</Label>*/}
          {/*  <InputAutocomplete*/}
          {/*    name="judge"*/}
          {/*    onChange={onChange}*/}
          {/*    loading={filtersLoading === "judges"}*/}
          {/*    defaultValue={judge?.name}*/}
          {/*    onOptionChange={e => setJudge(e)}*/}
          {/*    options={searchJudges.map(el => {*/}
          {/*      return {*/}
          {/*        label: el.name,*/}
          {/*        ...el,*/}
          {/*      }*/}
          {/*    })}*/}
          {/*  />*/}
          {/*</Box>*/}
          <CheckboxesGroup
            type="secondary"
            name="sourceName"
            label="Źródło"
            value={filters?.sourceName}
            onChange={onChange}
            options={[[t("SAOS"), "SAOS"]]}
          />
        </FilterWrapper>
      </Modal>
    </>
  )
}

export default SearchHeader

const FilterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.M};
  flex: 1 1;
`

const StyledSelect = styled.select`
  ${buttonStyle};
`

const StyledButton = styled(Button)`
  border-radius: 100px;
`

const Input = styled.input`
  font-family: "Manrope" !important;
  margin: 0;
  font-size: ${({ theme }) => theme.font.XL};
  font-weight: ${({ theme }) => theme.font.normal};
  line-height: normal;
  color: ${({ theme, color = "second" }) => theme.color[color]};
  background: none;
  padding: 0;
  border: none;
  flex: 1 1;
  height: 45px;
  padding: 0 ${({ theme }) => theme.space.XS} 0 ${({ theme }) => theme.space.S};

  &::placeholder {
    opacity: 0.5;
    font-weight: ${({ theme }) => theme.font.normal};
  }

  &:focus {
    outline: none;
  }
`

const Wrapper = styled(Box)`
  background: white;
  border-radius: 100px;
  align-items: stretch;
  padding: ${({ theme }) => theme.space.XXS};
  box-shadow: ${({ theme }) => theme.variable.boxShadow};

  form {
    display: flex;
    grid-gap: ${({ theme }) => theme.space.XS};
  }
`
