import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import Modal from "components/molecules/modal"
import Button from "components/atoms/button"
import { Box, Row } from "components/atoms/_atoms"
import { AIService, AISourceDocument } from "services/AIService"
import { SentryService } from "services"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import { P } from "components/atoms/typography"
import SearchSimilarDocumentBlock from "components/shared/blocks/SearchSimilarDocumentBlock"
import { splitIds } from "utils/id"

const Tab = ({
  type,
  label,
  currentType,
  setType,
  disabled,
}: {
  type: string
  label: string
  currentType?: string
  setType: (e: string) => void
  disabled?: boolean
}) => {
  const isActive = currentType === type

  return (
    <Button type={isActive ? "primary" : "secondary"} disabled={disabled}>
      {label}
    </Button>
  )
}

const DocumentSearchSimilar = ({ q = "", setShow }: { q?: string; setShow: (e: boolean) => void }) => {
  const { id = "" } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [tab, setTab] = useState("signatures")

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<AISourceDocument[] | null>(null)

  const handleSearch = async (e?: any) => {
    e?.preventDefault()
    try {
      setLoading(true)
      setData(null)

      const documents = await AIService.search(q)
      setData(documents)
    } catch (e) {
      SentryService.error("[ERROR AIService.search:", e)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleSearch()
  }, [q])

  const handleOnClick = (el: string) => {
    const ids = splitIds(id)
    if (ids.includes(el)) {
      navigate(location.pathname)
    } else {
      navigate(`${location.pathname}-${el}`)
    }
    setShow(false);
  }

  return (
    <Wrapper>
      <TabWrapper>
        <Tab type="signatures" currentType={tab} setType={setTab} label="Orzeczenia" />
        <Tab type="kodeks" currentType={tab} setType={setTab} label="Kodeksy" disabled />
        <Tab type="ustawy" currentType={tab} setType={setTab} label="Ustawy" disabled />
      </TabWrapper>
      <Content marginTop="M">
        {loading && (
          <>
            <LoaderBlock height="150px" />
            <LoaderBlock height="150px" />
            <LoaderBlock height="150px" />
          </>
        )}
        {!loading &&
          data &&
          data.length > 0 &&
          data
            .filter(function (item, pos) {
              return data?.findIndex(el => el.metadata.id === item.metadata.id) == pos
            })
            .map((document, index) => (
              <SearchSimilarDocumentBlock
                key={index}
                data={document}
                q={q}
                onClick={handleOnClick}
              />
            ))}
        {!loading && data?.length === 0 && (
          <Row marginTop="M" justifyContent="center">
            <P small>Brak dokumentów spełniających kryteria</P>
          </Row>
        )}
      </Content>
    </Wrapper>
  )
}

export const DocumentSearchSimilarModal = ({
  q,
  show,
  setShow,
}: {
  q?: string
  show: boolean
  setShow: (e: boolean) => void
}) => {
  return (
    <Modal
      icon="search"
      title="Szukaj podobnych informacji"
      show={show}
      setShow={setShow}
      maxWidth="1200px"
      type="side"
      background="background"
    >
      <DocumentSearchSimilar q={q} setShow={setShow} />
    </Modal>
  )
}

export default DocumentSearchSimilar

const TabWrapper = styled(Box)`
  grid-gap: ${({ theme }) => theme.space.S};
  display: flex;
  flex-wrap: wrap;
`

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Wrapper = styled(Box)``
